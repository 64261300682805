"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.activeRawMaterial = activeRawMaterial;
exports.createRawMaterial = createRawMaterial;
exports.createRawMaterialScheduledPrice = createRawMaterialScheduledPrice;
exports.deleteRawMaterial = deleteRawMaterial;
exports.deleteRawMaterialScheduledPrice = deleteRawMaterialScheduledPrice;
exports.editRawMaterial = editRawMaterial;
exports.findBySku = findBySku;
exports.getPackLabRawMaterials = getPackLabRawMaterials;
exports.getRawMaterial = getRawMaterial;
exports.getRawMaterialScheduledPrices = getRawMaterialScheduledPrices;
exports.getRawMaterials = getRawMaterials;
exports.hideRawMaterial = hideRawMaterial;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function getPackLabRawMaterials(params) {
  return (0, _request.default)({
    url: '/api/v2/raw-materials/list',
    method: 'post',
    timeout: 9000,
    // request timeout
    params
  });
}
function getRawMaterials(params) {
  return (0, _request.default)({
    url: '/api/v1/raw-materials',
    method: 'get',
    timeout: 9000,
    // request timeout
    params
  });
}
function getRawMaterial(id) {
  return (0, _request.default)({
    url: '/api/v1/raw-materials/' + id,
    method: 'get'
  });
}
function createRawMaterial(data) {
  return (0, _request.default)({
    url: '/api/v1/raw-materials',
    data,
    method: 'post'
  });
}
function editRawMaterial(data) {
  return (0, _request.default)({
    url: '/api/v1/raw-materials/' + data.uuid,
    data,
    method: 'put'
  });
}
function deleteRawMaterial(id) {
  return (0, _request.default)({
    url: '/api/v1/raw-materials/' + id,
    method: 'delete'
  });
}
function hideRawMaterial(id) {
  return (0, _request.default)({
    url: `/api/v1/raw-material/${id}/hide`,
    method: 'post'
  });
}
function activeRawMaterial(id) {
  return (0, _request.default)({
    url: `/api/v1/raw-material/${id}/active`,
    method: 'post'
  });
}
function findBySku(key) {
  return (0, _request.default)({
    url: '/api/v1/raw-material/findSku',
    method: 'post',
    key
  });
}
function getRawMaterialScheduledPrices(params) {
  return (0, _request.default)({
    url: '/api/v1/raw-material-scheduled-price',
    method: 'get',
    params
  });
}
function createRawMaterialScheduledPrice(data) {
  return (0, _request.default)({
    url: '/api/v1/raw-material-scheduled-price',
    data,
    method: 'post'
  });
}
function deleteRawMaterialScheduledPrice(id) {
  return (0, _request.default)({
    url: '/api/v1/raw-material-scheduled-price/' + id,
    method: 'delete'
  });
}